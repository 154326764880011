export default {
  presets: {
    light: {
      backgroundPrimary: '#F4F6F8',
      backgroundSecondary: '#FFFFFF',
      backgroundCardPrimary: '#F7F9F9',
      backgroundCardSecondary: '#ECFDE6',
      success: '#228200',
      info: '#158DE3',
      danger: '#E42222',
      warning: '#FFD43A',
      primary: '#006674',
      focus: '#f9b30b',
      label: '#FA6620',
      light: '#f0f0f0',
      dark: '#000',
      banana: '#f9b30b',
    },
    dark: {
      backgroundCardPrimary: '#111827',
      backgroundCardSecondary: '#0f172a',
    },
  },
};
