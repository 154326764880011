// breadcrumbConfig.ts

import { RouteLocationNormalizedLoaded } from 'vue-router';

export interface BreadcrumbConfig {
  name: string;
  text: string | ((route: RouteLocationNormalizedLoaded) => string);
  showInMenu?: boolean;
  icon?: string;
  children?: {
    [key: string]: BreadcrumbConfig;
  };
}

const breadcrumbConfig: { [key: string]: BreadcrumbConfig } = {
  'review-cycle': {
    name: 'review-cycle',
    text: 'Review Cycle',
    icon: 'group',
    children: {
      'review-cycle-assignment': {
        name: 'review-cycle-assignment',
        // text: (route) => `Assignment ${route.params.id}`,
        text: 'Assignments',
        showInMenu: false,
      },
      // 'review-cycle-summary': {
      //   name: 'review-cycle-summary',
      //   text: 'Summary',
      // },
    },
  },
  'my-assignment': {
    name: 'my-assignment',
    text: 'My Assignments',
    icon: 'task',
    // children: {
    //   'review-cycle-assignment': {
    //     name: 'review-cycle-assignment',
    //     // text: (route) => `Assignment ${route.params.id}`,
    //     text: 'Assignments',
    //     showInMenu: false,
    //   },
    //   // 'review-cycle-summary': {
    //   //   name: 'review-cycle-summary',
    //   //   text: 'Summary',
    //   // },
    // },
  },
  staffs: {
    name: 'staffs',
    text: 'Staffs',
    icon: 'reviews',
    children: {
      // 'staff-details': {
      //   name: 'staff-details',
      //   text: (route) => `Staff ${route.params.id}`,
      //   showInMenu: false,
      // },
      // 'staff-performance': {
      //   name: 'staff-performance',
      //   text: 'Performance',
      // },
    },
  },
  // departments: {
  //   name: 'departments',
  //   text: 'Departments',
  // },
};

export default breadcrumbConfig;
