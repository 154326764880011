<!-- Menu.vue -->
<template>
  <VaSidebar
    v-model="writableVisible"
    minimized-width="0"
    :width="sidebarWidth"
    :color="color"
  >
    <nav>
      <MenuItems :items="menuItems" />
    </nav>
  </VaSidebar>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import breadcrumbConfig, {
  BreadcrumbConfig,
} from 'src/components/app-layout-navigation/breadcrumbConfig';
import MenuItems from './MenuItems.vue';
import { useColors } from 'vuestic-ui';

export interface MenuItem {
  name: string;
  text: string;
  path: string;
  icon?: string;
  children?: MenuItem[];
  requiresParams: boolean;
}

export default defineComponent({
  components: {
    MenuItems,
  },
  props: {
    visible: { type: Boolean, default: true },
    mobile: { type: Boolean, default: false },
  },
  emits: ['update:visible'],
  setup(props, { emit }) {
    const { getColor, colorToRgba } = useColors();
    const route = useRoute();
    const router = useRouter();

    const writableVisible = computed({
      get: () => props.visible,
      set: (v: boolean) => emit('update:visible', v),
    });

    const createMenuItem = (
      config: BreadcrumbConfig,
      parentPath: string = '',
    ): MenuItem | null => {
      if (!config || config.showInMenu === false) {
        return null;
      }

      let path = `${parentPath}/${config.name || ''}`.replace(/\/+/g, '/');
      let requiresParams = false;

      try {
        const resolvedRoute = router.resolve({ name: config.name });

        if (
          resolvedRoute &&
          resolvedRoute.matched &&
          resolvedRoute.matched.length > 0
        ) {
          const routeRecord = resolvedRoute.matched[0];
          if (routeRecord && routeRecord.path) {
            // Check if the route has any params in its path
            requiresParams = routeRecord.path.includes(':');
          }
        }

        // If the route requires params, we'll use a placeholder in the path
        if (requiresParams) {
          path = path
            .split('/')
            .map((segment) => (segment.startsWith(':') ? '_' : segment))
            .join('/');
        }
      } catch (error) {
        console.warn(`Error resolving route ${config.name}:`, error);
        // If we can't resolve the route, we'll still create a menu item, but mark it as requiring params
        requiresParams = true;
      }

      const item: MenuItem = {
        name: config.name || '',
        text:
          typeof config.text === 'function'
            ? config.text(route)
            : config.text || '',
        path,
        icon: config.icon,
        requiresParams,
      };

      if (config.children) {
        item.children = Object.entries(config.children)
          .map(([, childConfig]) => createMenuItem(childConfig, path))
          .filter((child): child is MenuItem => child !== null);

        if (item.children.length === 0) {
          delete item.children;
        }
      }

      return item;
    };

    const menuItems = computed<MenuItem[]>(() => {
      if (!breadcrumbConfig || typeof breadcrumbConfig !== 'object') {
        console.warn('Invalid breadcrumbConfig:', breadcrumbConfig);
        return [];
      }

      return Object.entries(breadcrumbConfig)
        .map(([, config]) => createMenuItem(config))
        .filter((item): item is MenuItem => item !== null);
    });

    const sidebarWidth = computed(() => (props.mobile ? '180vw' : '220px'));
    const color = computed(() => getColor('background-secondary'));
    const activeColor = computed(() => colorToRgba(getColor('focus'), 0.1));

    return { menuItems, sidebarWidth, color, activeColor, writableVisible };
  },
});
</script>
