<template>
  <div class="flex gap-2">
    <VaIconMenuCollapsed
      class="cursor-pointer"
      :class="{ 'x-flip': !isSidebarMinimized }"
      :color="collapseIconColor"
      @click="isSidebarMinimized = !isSidebarMinimized"
    />

    <nav class="flex items-center">
      <ol class="breadcrumb">
        <li
          v-for="(crumb, index) in breadcrumbs"
          :key="index"
          class="breadcrumb-item"
        >
          <RouterLink
            v-if="index < breadcrumbs.length - 1"
            :to="{ name: crumb.name }"
          >
            {{ crumb.text }}
          </RouterLink>
          <span v-else class="text-black">{{ crumb.text }}</span>
        </li>
      </ol>
    </nav>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useRoute, RouteLocationNormalizedLoaded } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useColors } from 'vuestic-ui';
import VaIconMenuCollapsed from '../icons/VaIconMenuCollapsed.vue';
import { storeToRefs } from 'pinia';
import { useGlobalStore } from '../../stores/global-store';
import breadcrumbConfig, { BreadcrumbConfig } from './breadcrumbConfig';

const { isSidebarMinimized } = storeToRefs(useGlobalStore());
const route = useRoute();
const { t } = useI18n();
const { getColor } = useColors();

interface Breadcrumb {
  name: string;
  text: string;
}

const findBreadcrumbPath = (
  targetName: string,
  config: { [key: string]: BreadcrumbConfig } = breadcrumbConfig,
): BreadcrumbConfig[] => {
  for (const [key, value] of Object.entries(config)) {
    if (key === targetName) {
      return [value];
    }
    if (value.children) {
      const childPath = findBreadcrumbPath(targetName, value.children);
      if (childPath.length) {
        return [value, ...childPath];
      }
    }
  }
  return [];
};

const getBreadcrumbText = (
  config: BreadcrumbConfig,
  route: RouteLocationNormalizedLoaded,
): string => {
  return typeof config.text === 'function' ? config.text(route) : config.text;
};

const createBreadcrumb = (
  config: BreadcrumbConfig,
  route: RouteLocationNormalizedLoaded,
): Breadcrumb => ({
  name: config.name,
  text: getBreadcrumbText(config, route),
});

const breadcrumbs = computed<Breadcrumb[]>(() => {
  const breadcrumbPath = findBreadcrumbPath(route.name as string);

  if (!breadcrumbPath.length) {
    console.warn(`No breadcrumb path found for route: ${route.name}`);
    return [];
  }

  return breadcrumbPath.map((config) => createBreadcrumb(config, route));
});

const collapseIconColor = computed(() => getColor('secondary'));
</script>

<style lang="scss" scoped>
.x-flip {
  transform: scaleX(-100%);
}

.breadcrumb {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

.breadcrumb-item:not(:last-child)::after {
  content: '/';
  margin: 0 5px;
}

.breadcrumb-item a {
  text-decoration: none;
}

.breadcrumb-item a:hover {
  text-decoration: underline;
}
</style>
