<template>
  <div class="flex items-center logo">
    <img src="./favicon.png" class="h-auto mx-auto" width="32" />
    <div class="ml-3">360 Review Club</div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useColors } from 'vuestic-ui';

const { getColor } = useColors();

const props = withDefaults(
  defineProps<{
    height?: number;
    start?: string;
    end?: string;
  }>(),
  {
    height: 18,
    start: 'primary',
    end: undefined,
  },
);

const colorsComputed = computed(() => {
  return {
    start: getColor(props.start),
    end: getColor(props.end || props.start),
  };
});
</script>
<style scoped>
.logo {
  font-size: 16px;
  font-weight: 600;
  /* padding: 0.25rem 1rem; */
  /* border-radius: 36px;
  background-color: #fcd26f;
  border: solid 1px #f9b30b; */
}
</style>
