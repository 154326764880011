import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import AppLayout from '../layouts/AppLayout.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/:pathMatch(.*)*',
    redirect: { name: 'review-cycle' },
  },
  {
    name: 'admin',
    path: '/',
    component: AppLayout,
    redirect: { name: 'review-cycle' },
    children: [
      {
        name: 'staffs',
        path: 'staffs',
        component: () => import('../pages/staffs/StaffsPage.vue'),
      },
      {
        name: 'review-cycle',
        path: 'review-cycle',
        component: () => import('../pages/review-cycle/Page.vue'),
      },
      {
        name: 'review-cycle-assignment',
        path: 'review-cycle/:id',
        component: () => import('../pages/review-cycle/EditPage.vue'),
      },
      {
        name: 'my-assignment',
        path: 'my-assignment',
        component: () => import('../pages/my-assignment/Page.vue'),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    // For some reason using documentation example doesn't scroll on page navigation.
    if (to.hash) {
      return { el: to.hash, behavior: 'smooth' };
    } else {
      window.scrollTo(0, 0);
    }
  },
  routes,
});

export default router;
