<!-- MenuItems.vue -->
<template>
  <ul :class="depth === 0 ? 'menu' : 'submenu'">
    <li
      v-for="item in items"
      :key="item.name"
      :class="depth === 0 ? 'menu-item' : 'submenu-item'"
    >
      <RouterLink
        v-if="!item.requiresParams"
        :to="{ name: item.name }"
        :class="{ active: isActive(item) }"
      >
        <VaIcon :name="item.icon" />
        {{ item.text }}
      </RouterLink>
      <span v-else :class="{ active: isActive(item) }">{{ item.text }}</span>
      <MenuItems
        v-if="item.children && item.children.length"
        :items="item.children"
        :depth="depth + 1"
      />
    </li>
  </ul>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { useRoute } from 'vue-router';
import MenuItem from './MenuItems.vue';

export default defineComponent({
  name: 'MenuItems',
  props: {
    items: {
      type: Array as PropType<MenuItem[]>,
      required: true,
    },
    depth: {
      type: Number,
      default: 0,
    },
  },
  setup() {
    const route = useRoute();

    const isActive = (item: MenuItem): boolean => {
      if (item.requiresParams) {
        // For items with params, check if the current route name matches
        return route.name === item.name;
      }

      // Check if the current route matches the item
      if (route.name === item.name) {
        return true;
      }

      // Check if any parent route matches the item
      const parentRoute = route.matched.find((r) => r.name === item.name);
      if (parentRoute) {
        return true;
      }

      // If no match found, check if the current path starts with the item's path
      return route.path.startsWith(item.path);
    };

    return { isActive };
  },
});
</script>

<style scoped>
.menu,
.submenu {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.menu {
  /* background: #fff6db; */
  padding-left: 0;
}

.submenu {
  /* background: #fff; */
  border-bottom: solid 1px #ffd982;
}

.menu-item .va-icon {
  margin-right: 1rem;
}

.menu-item > a {
  display: flex;
  align-items: center;
  @apply px-4 py-4;
}

.submenu-item > a {
  display: flex;
  @apply px-4 py-2;
}

a:hover {
  background: #ffd982;
}

.active {
  background: #ffe2a1;
  font-weight: bold;
}
</style>
